import { render } from "./Navbar.vue?vue&type=template&id=d3c69dcc&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=js"
export * from "./Navbar.vue?vue&type=script&lang=js"

import "./Navbar.vue?vue&type=style&index=0&id=d3c69dcc&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-d3c69dcc"
/* custom blocks */
import block0 from "./Navbar.vue?vue&type=custom&index=0&blockType=router-link&class=link"
if (typeof block0 === 'function') block0(script)


export default script