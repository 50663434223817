<template>

  <div  class="flexcontainer positioncontainer">
    <p class="posname">{{ position }}</p>
    <div class="poscont">
      <div class="left">
        <div v-for="player in players" class="player">
          <div class="onethird">{{ player.name }}</div>
          <div><MemberEventPredictedAttendance class="onethird" :eid="eid" :mid="player.id" :mempredatt="player" /></div>
          <div><AdminEventPredictedPosition class="onethird" :eid="eid" :mid="player.id" :mempredatt="player" @poschanged="posChanged" /></div>
        </div>
      </div>

      <div class="right">
        <AdminEventPredictedDeps :eid="eid" :position="position" :deps="deps" />
      </div>
    </div>
  </div>

</template>

<script>
import MemberEventPredictedAttendance from '@/components/events/MemberEventPredictedAttendance';
import AdminEventPredictedPosition from '@/components/events/AdminEventPredictedPosition';
import AdminEventPredictedDeps from '@/components/events/AdminEventPredictedDeps';
import { computed  } from 'vue';

export default {
  name: "AdminPositionPredictedAttendance",
  props: {
    eid: String,
    position: String,
    players: Array,
    deps: Array
  },
  components: {
    MemberEventPredictedAttendance,
    AdminEventPredictedPosition,
    AdminEventPredictedDeps
  },
  emits: [
    'poschanged'
  ],
  setup (props, { emit }) {
    console.log('AdminPositionPredictedAttendance setup entered');

    const posChanged = () => {
      // pass the change up the stack so component owning the data can handle it
      emit('poschanged');
    }

    console.log('AdminPositionPredictedAttendance setup exited');
// EXPOSED PROPERTIES
    return {
      posChanged
    };
  }
}

</script>

<style scoped>

.positioncontainer {
  padding: 0;
  margin: 0;
  border: 0;
  flex-direction: column;
}

.posname {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.poscont {
  display: flex;
}

.left {
  margin: 5px 5px 0 0;
  padding: 5px;
  background: #f0f0f0;
  min-height: 2rem;
  flex: 0 0 70%;
}

.right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0 0 5px;
  padding: 5px;
  flex: 0 0 29%;
  background: #f0f0f0;
}

@media only screen and (max-width: 600px) {
  .poscont {
    flex-wrap: wrap;
  }
  .left {
    flex: 0 0 100%;
    margin: 5px 0 0 0;
  }
  .right {
    flex: 0 0 100%;
    margin: 5px 0 0 0;
  }
}

.player {
  min-height: 1.6rem;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 3px 0;
  display: flex;
}

.player div {
  flex: 0 0 33.333%
}

.player:first-child {
  padding-bottom: 3px;
  border-top: 0;
}
.player:last-child {
  padding-top: 3px;
  border-bottom: 0;
}

</style>


