<template>
  <Waiting v-if="!loaded" message="loading details..." />
  <div v-else>
    <div class="pageheader">Confirm your attendance</div>
    <div class="flexcontainer thiscontainer">
      <div>
        <p><span>Date: </span> {{ new Date(event.date).toDateString() }} </p>
        <p><span>Start time: </span> {{ event.starttime.substring(0,5) }} </p>
        <p><span>Title: </span> {{ event.title }}</p>
        <p><span>Venue: </span> {{ event.location }}</p>

        <p>Click "I'm here" to confirm your presence! </p>
      </div>
      <div>
        <MemberEventActualAttendance :memactatt="memactatt" :eid="eid" :mid="mid" @actattchanged="changed"/>
      </div>
    </div>
  </div>

</template>

<script>

import MemberEventActualAttendance from '@/components/events/MemberEventActualAttendance';
import Waiting from '@/components/global/Waiting';
import useAuth from '@/composables/useAuth';
import useAttendance from '@/composables/useAttendance';
import useEvents from '@/composables/useEvents';
import { ref} from 'vue';
import { useRouter } from 'vue-router';

export default {

  name: "RegisterAttendanceContent",
  components: {
    MemberEventActualAttendance,
    Waiting
  },
  props: {
    eid: String
  },
  setup(props) {
    console.log('RegisterAttendanceContent setup entered');

// EXTERNAL PROPERTIES
    const { loggedinuser } = useAuth();
    const { getActualAttendance } = useAttendance();
    const { getEventDetails } = useEvents();
    const router = useRouter();

// DATA
    const mid = loggedinuser.value.id;
    const memactatt = ref(null);
    const event = ref(null);
    const loaded = ref(false);

// FUNCTIONS

    const changed = () => {
      router.push({name: 'member events'});
    }

    const loadData = async () => {
      const data = await getActualAttendance(props.eid, mid);
      console.log('Data:', data, ' Data length:', data.length);
      memactatt.value = (data.mid?2:1);
      console.log('Memactatt:', memactatt.value);

      event.value = await getEventDetails(props.eid);
      console.log('Event:', event.value);

      loaded.value = memactatt.value && event.value;
    }

// ENTRYPOINT
    loadData();

    console.log('RegisterAttendanceContent setup exited');
// EXPOSED PROPERTIES
    return {
      memactatt,
      event,
      mid,
      loaded,
      changed
    };
  }

}
</script>

<style scoped>
.thiscontainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thiscontainer div {
}
</style>