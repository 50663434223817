<template>
  <Waiting v-if="loading" message="loading details..." />

  <div v-else>
    <div class="pageheader">
      <p> {{ formatteddate }}  {{ formattedstart }}  {{ event.title }}</p>
      <p>View, add or change Event Details </p>
    </div>

    <div class="links" v-if="eid>0">
      <router-link class="link" :to="{name: 'predicted attendance', params: {eid: eid } }">Predicted attendance</router-link>
      <router-link class="link" :to="{name: 'actual attendance', params: {eid: eid } }">Actual attendance</router-link>
    </div>

    <div class="flexcontainer formcontainer">
      <form @submit.prevent="handleSubmit">
        <div v-if="validationerror != ''" class="error">{{ validationerror }}</div>

        <div class="formitem">
          <div class="left">
            Date:
          </div>

          <div class="right">
            <datepicker-lite
                :value-attr="event.date"
                @value-changed="dateSelected"
                class-attr="forminput"
                placeholder-attr="Select the date"
                :locale="dateformat"
            />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Start time:
          </div>

          <div class="right">
            <input class="forminput" type="text" placeholder="Enter a time hh:mm (00:00 to 23:59)" v-model.trim="event.starttime" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            End time (optional):
          </div>

          <div class="right">
            <input class="forminput" type="text" placeholder="Enter a time hh:mm (00:00 to 23:59)" v-model.trim="event.endtime" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Urgent until (optional):
          </div>

          <div class="right">
            <datepicker-lite
                :value-attr="event.urgentuntil"
                @value-changed="urgentuntilSelected"
                class-attr="forminput"
                placeholder-attr="Select the date"
                :locale="dateformat"
            />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event title:
          </div>

          <div class="right">
            <textarea class="forminput" v-model.trim="event.title" ></textarea>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event venue:
          </div>

          <div class="right">
            <textarea maxlength="255" class="forminput" v-model.trim="event.location" ></textarea>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event type:
          </div>

          <div class="right">
            <select class="forminput capfirst" v-model.trim="event.type">
              <option class="capfirst" v-for="(type) in typevals"
                  :value="type"
                  :key="type"
                  :selected="type===event.type">
                {{ type }}
              </option>
            </select>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Event status:
          </div>

          <div class="right">
            <select class="forminput capfirst" v-model.trim="event.status">
              <option
                  v-for="status in statusvals"
                  :value="status"
                  :key="status"
                  :selected="status===event.status">
                {{ status }}
              </option>
            </select>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Info for members:
          </div>

          <div class="right">
            <textarea maxlength="4095" class="forminput" v-model.trim="event.info" ></textarea>
          </div>
        </div>


        <input v-if="eid==0" class="button" type="submit" value="Submit" />
        <input v-if="eid>0" class="button" type="submit" value="Submit changes" />
        <button v-if="eid>0" class="button" @click.prevent="deleteEvent">Delete event</button>
        <router-link class="button" :to="{name: 'admin events'}">Cancel</router-link>
      </form>

    </div>

    <div class="container">
      <div class="atlinks">
        <button class="button" @click="copyToClipboard('PAL')"> Copy Predicted attendance link:</button>
        <p id="PAL" class="linkitem">{{ registerlinks.predictedattendancelink }}</p>
        <button class="button" @click="copyToClipboard('AAL')"> Copy Actual attendance link:</button>
        <p id="AAL" class="linkitem">{{ registerlinks.actualattendancelink}}</p>
      </div>
    </div>

  </div>
</template>

<script>
import useSettings from '@/composables/useSettings';
import useEvents from '@/composables/useEvents';
import { ref, computed } from 'vue';
import DatepickerLite from 'vue3-datepicker-lite';
import { useRouter } from 'vue-router';

export default {
  name: "AdminEventDetailsContent",
  components: {
    DatepickerLite
  },
  props: {
    eid: String
  },

  setup(props) {
    console.log('AdminEventDetailsContent setup entered');

    // this is the only way I could get copy to clipboard working
    // it may not be the best way
    // it's not very vue-centric!
    const copyToClipboard = (elementId) => {
      // Create an auxiliary hidden input
      let aux = document.createElement("input");
      // Get the text from the element passed into the input
      aux.setAttribute("value", document.getElementById(elementId).innerHTML);
      // Append the aux input to the body
      document.body.appendChild(aux);
      // Highlight the content
      aux.select();
      // Execute the copy command
      document.execCommand("copy");
      // Remove the input from the body
      document.body.removeChild(aux);
    }

// EXTERNAL PROPERTIES
    const { eventtypes, eventstatuses } = useSettings();
    const { getEventDetails, changeEvent, getRegisterLinks } = useEvents();
    const router = useRouter();

// COMPUTED PROPERTIES

// DATA
    const loading = ref(null);
    const typevals = ['Select an event type'].concat(eventtypes);
    const statusvals = ['Select a status'].concat(eventstatuses);
    const event = ref(null);
    const formatteddate = ref(null);
    const formattedstart = ref(null);
    const errors = ref([]);
    const validationerror = ref('');

    const dateformat =   {format: "YYYY-MM-DD"};
    const registerlinks = getRegisterLinks(props.eid);

// FUNCTIONS
    const dateSelected = (value) => {
      console.log('dateSelected entered');
      event.value.date = value;
      formatteddate.value = new Date(event.value.date).toDateString();
      console.log('dateSelected exited');
    }

    const urgentuntilSelected = (value) => {
      console.log('urgentuntilSelected entered');
      event.value.urgentuntil = value;
      console.log('urgentuntilSelected exited');
    }

    const handleSubmit = async (submitevent) => {
      console.log('handleSubmit entered');
      console.log('SUBMIT EVENT TARGET', submitevent.target);
      console.log('EVENT VALUE', event.value);
      errors.value = [];
      validationerror.value = '';
      validateInput();
      if (errors.value.length>0) {
        validationerror.value =  errors.value.join('; ');
        return false
      } else {
        await changeEvent(props.eid, event.value);
        router.push({name: 'admin events'});
      }
      console.log('handleSubmit exited');
    }

    const validateInput = () => {
      console.log('validateInput entered');
      if (event.value.date == '') {
        errors.value.push('You must provide a date');
      }
      if (event.value.starttime == '') {
        errors.value.push('You must provide a start time');
      } else {
        const starttimebits = event.value.starttime.split(':');
        if (
            starttimebits.length != 2 ||
            isNaN(starttimebits[0]) ||
            starttimebits[0] < 0 ||
            starttimebits[0] > 23 ||
            isNaN(starttimebits[1]) ||
            starttimebits[1] < 0 ||
            starttimebits[1] > 59
        ) {
          errors.value.push('Invalid starttime format');
        } else {
          //starttime is valid
          if (!(event.value.endtime == '' || event.value.endtime == null)) {
            const endtimebits = event.value.endtime.split(':');
            if (
                endtimebits.length != 2 ||
                isNaN(endtimebits[0]) ||
                endtimebits[0] < 0 ||
                endtimebits[0] > 23 ||
                isNaN(endtimebits[1]) ||
                endtimebits[1] < 0 ||
                endtimebits[1] > 59
            ) {
              errors.value.push('Invalid endtime format');
            } else {
              if (event.value.starttime > event.value.endtime) { //character compare
                errors.value.push('Starttime is after endtime');
              }
            }
          }
        }
      }
      if (event.value.urgentuntil != '' && event.value.urgentuntil < new Date().toISOString().slice(0, 10)) {
        errors.value.push('Urgent until date is in the past');
      }
      if (event.value.title == '') {
        errors.value.push('You must provide an event title');
      }
      if (event.value.location == '') {
        errors.value.push('You must provide an event location');
      }
      if (event.value.type == 'select an event type') {
        errors.value.push('You must select an event type');
      }
      if (event.value.status == 'select a status') {
        errors.value.push('You must select an event status');
      }
      console.log('validateInput exited');
    }

    const deleteEvent = async (event) => {
      console.log('deleteEvent entered', event.target.value);
      if(confirm('are you sure?')) {
        await changeEvent(props.eid, null);
        router.push({name: 'admin events'});
      }
      console.log('deleteEvent exited');
    }

    const loadData = async () => {
      console.log('loadData entered');
      loading.value = true;

      if (props.eid == 0) {
        // new event
        event.value = {
          date: '',
          starttime: '',
          endtime: null,
          title: '',
          location: '',
          type: 'Select an event type',
          status: 'Select a status',
          urgentuntil: null,
          info: null
        }
        formatteddate.value = 'New';
        formattedstart.value = 'Event';
      } else {
        // load the event details
        event.value = await getEventDetails(props.eid);
        console.log('EVENT DATA:', event.value);
        event.value.starttime = event.value.starttime.substring(0,5);
        event.value.endtime = (event.value.endtime==null?null:event.value.endtime.substring(0,5));
        formatteddate.value = new Date(event.value.date).toDateString();
        formattedstart.value = event.value.starttime.substring(0,5);
      }

      loading.value = false;
      console.log('loadData exited');
    }

// ENTRY POINT
    loadData();

    console.log('AdminEventDetailsContent setup exited');
    return {
      loading,
      event,
      formatteddate,
      formattedstart,
      dateformat,
      typevals,
      statusvals,
      validationerror,
      registerlinks,
      dateSelected,
      urgentuntilSelected,
      handleSubmit,
      deleteEvent,
      copyToClipboard
    };

  }

}
// EXTERNAL PROPERTIES
// COMPUTED PROPERTIES
// DATA
// FUNCTIONS
// ENTRY POINT
// EXPOSED PROPERTIES

</script>

<style>

.forminput {
  width: 100%;
}

</style>

<style scoped>

.formcontainer {
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.formitem {
  display: flex;
}

.left {
  margin: 5px 5px 0 0 ;
  padding: 5px;
  background: #f0f0f0;
  flex: 0 0 28%;
}

.right {
  margin: 5px 0 0 5px;
  padding: 5px;
  flex: 0 0 70%;
  max-width: 70%;
  background: #f0f0f0;
}

.links {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
}

.linkitem {
  overflow-wrap: break-word;
  font-weight: bold;
}

.atlinks .button {
  width: auto;
}
</style>

