<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <AdminMemberDataContent :mid="mid"/>
    </template>
  </Authorise>

</template>

<script>
import AdminMemberDataContent from '@/components/viewcontents/AdminMemberDataContent';

export default {
  name: 'AdminMemberData',
  components: {
    AdminMemberDataContent
  },
  props: {
    mid: String
  }
};

</script>

<style scoped>

</style>
