<template>

  <Waiting v-if="loading" message="loading members..." />

  <div v-else>

    <div class="pageheader">Manage members</div>
    <button class="button"><router-link class="link" :to="{name: 'member details', params: {mid: 0 } }">Create new member</router-link></button>

    <div v-for="(member, index) in memberlist" :key="index">
      <AdminSingleMember :member="member" />
    </div>

  </div>

</template>

<script>
//import ;
import AdminSingleMember from "@/components/members/AdminSingleMember";
import useMembers from "@/composables/useMembers";
import {ref} from 'vue';

export default {
  name: 'AdminMembersContent',
  components: {
    AdminSingleMember,
  },
  setup() {
    console.log('AdminMembersContent setup entered');

// EXTERNAL PROPERTIES
    const { getMemberList } = useMembers();

// DATA
    const loading = ref(null);
    const memberlist = ref(null)

// FUNCTIONS
    const loadData = async () => {
      console.log('loadData entered');
      loading.value = true;
      memberlist.value = await getMemberList();
      loading.value = false;
      console.log('loadData exited');
    }
// ENTRYPOINT
    loadData();

    console.log('AdminMembersContent setup exited');
// EXPOSED PROPERTIES
    return { loading, memberlist };
  }
}

</script>

<style scoped>
.link {
  color: #f0f8f8;
  text-decoration: none;
}
</style>
