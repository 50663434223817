<template>
  <!--This is an example text that won't be displayed in the browser-->

    <div v-if="!authenticating">
      <router-view />
    </div>
    <div v-else>
      <Waiting message="Just checking that you are logged in....." />
    </div>

</template>

<script>
import { ref, watch } from 'vue'
import useAuth from '@/composables/useAuth.js'

export default {
  name: 'App',
  components: {
  },
  setup() {
    // this function creates a new app
    // is entered whenever a page is accessed via the browser's address bar
    // but not when routing is internal - i.e. done by vue router
    // at the point of entry, there will be no loggedinuser
    // there may be a token in local store or session store which may or may not be valid
    // the token, loggedinuser and autherror are "owned" by useAuth
    // this component initialises the current logged in user and
    // looks after storing/removing the token in/from local or session store

    console.log('App setup entered');

// EXTERNAL PROPERTIES
    const { token, stayloggedin, initLoggedinUser } = useAuth();

// DATA
    const authenticating = ref(true);

// FUNCTIONS
    const init = async () => {
      console.log('Init entered');

      await initLoggedinUser();
      authenticating.value = false;

      console.log('Init exited');
    }

    watch(token, (token, oldtoken) => {
      // if the value of token has been changed in useAuth, the change is detected here
      // a change to null causes all local and session data to be removed
      // a change to a non-null value causes it to be written to local or session store
      // depending on the value of stayloggedin
      console.log('Token changed from ', oldtoken, ' to ', token);
      if (token) {
        if (stayloggedin.value) {
          localStorage.setItem('token', token);
        } else {
          sessionStorage.setItem('token', token);
        }
      } else {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
      }
    })


// ENTRY POINT
    init()

    console.log('App setup exited');
// EXPOSED PROPERTIES
    return { authenticating }
  }
}

// EXTERNAL PROPERTIES
// COMPUTED PROPERTIES
// DATA
// FUNCTIONS
// ENTRY POINT
// EXPOSED PROPERTIES

</script>


