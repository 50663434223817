<template>
    <div class="modal">
      <div class="modalbackdrop" @click="closeModal()"/>

      <div class="modalcontent">
        <div class="modalheader">
          <slot name="header"/>
          <button type="button" class="modalclose" @click="closeModal()">Close</button>
        </div>

        <div class="modalbody">
          <slot name="body"/>
        </div>

      </div>
    </div>
</template>

<script>
export default {
  name: "Modal",
  emits: [
    'close'
  ],
  setup(props, { emit }) {
    console.log('Modal setup entered')

// FUNCTIONS
    const closeModal = () => {
      emit('close')
    };

    console.log('Modal setup exited')
// EXPOSED PROPERTIES
    return { closeModal };
  }

};

</script>


<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.modalbackdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.modalcontent {
  background-color: #fff;
  position: relative;
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 2;
}
.modalclose {
  width: 50px;
  height: 30px;
  cursor: pointer;
}
.modalheader {
  padding: 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modalbody {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
</style>