<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <MemberEventsContent />
    </template>
  </Authorise>

</template>

<script>
import MemberEventsContent from '@/components/viewcontents/MemberEventsContent';

export default {
  name: 'MemberEvents',
  components: {
    MemberEventsContent
  }
};

</script>

<style scoped>

</style>