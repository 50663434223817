<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <MeetingsContent />
    </template>
  </Authorise>

</template>

<script>
import MeetingsContent from '@/components/viewcontents/MeetingsContent';

export default {
  name: 'Meetings',
  components: {
    MeetingsContent
  }
};

</script>

<style scoped>

</style>