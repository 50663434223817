<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <AdminEventActualAttendanceContent :eid="eid"/>
    </template>
  </Authorise>

</template>

<script>
import AdminEventActualAttendanceContent from '@/components/viewcontents/AdminEventActualAttendanceContent';

export default {
  name: 'AdminEventActualAttendance',
  components: {
    AdminEventActualAttendanceContent
  },
  props: {
    eid: String
  }
};

</script>

<style scoped>

</style>
