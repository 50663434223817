<template>

  <Navbar />
  <AuthoriseAdmin>
    <template v-slot:viewcontent>
      <AdminMembersContent />
    </template>
  </AuthoriseAdmin>

</template>

<script>
import AdminMembersContent from '@/components/viewcontents/AdminMembersContent';

export default {
  name: 'AdminMembers',
  components: {
    AdminMembersContent
  }
};

</script>

<style scoped>

</style>