import { createApp } from 'vue';
import Waiting from '@/components/global/Waiting';
import Modal from '@/components/global/Modal';
import Error from '@/components/global/Error';
import Navbar from '@/components/global/Navbar';
import Authorise from '@/components/global/Authorise';
import AuthoriseAdmin from '@/components/global/AuthoriseAdmin';
import LoginForm from '@/components/global/LoginForm';
import App from './App.vue';
import router from './router';

const app = createApp(App).use(router);
app.component('Modal', Modal);
app.component('Error', Error);
app.component('Waiting', Waiting);
app.component('Navbar', Navbar);
app.component('Authorise', Authorise);
app.component('AuthoriseAdmin', AuthoriseAdmin);
app.component('LoginForm', LoginForm);
app.mount('#app')

// import global css
import '@/assets/main.css';
