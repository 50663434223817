<template>
  <Waiting v-if="loading" message="loading details..." />

  <div v-else>
    <div class="pageheader">
      <p>View, add or change Member Details </p>
    </div>

    <div class="flexcontainer formcontainer">
      <form @submit.prevent="handleSubmit">

        <div class="formitem">
          <div class="left">
            First name:
          </div>

          <div class="right">
            <input required class="forminput" type="text" placeholder="Enter first name" v-model="member.firstname" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Last name:
          </div>

          <div class="right">
            <input required class="forminput" type="text" placeholder="Enter last name" v-model="member.lastname" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Email:
          </div>

          <div class="right">
            <input required class="forminput" type="email" placeholder="Enter email address" v-model="member.email" />
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Role:
          </div>

          <div class="right">
            <select class="forminput" v-model="member.role">
              <option
                  v-for="(value, name) in roles"
                  :value="name"
                  :key="value"
                  :selected="name===member.role">
                {{ value }}
              </option>
            </select>
          </div>
        </div>

        <div class="formitem">
          <div class="left">
            Position: <span v-if="positionvalidationerror != ''" class="error">{{ positionerror }}</span>
          </div>

          <div class="right">
            <select class="forminput" v-model="member.position">
              <option
                  v-for="(position) in posvals"
                  :value="position"
                  :key="position"
                  :selected="position===member.position">
                {{ position }}
              </option>
            </select>
          </div>

        </div>

        <div class="formitem">
          <div class="left">
            Active:
          </div>

          <div class="right">
            <select class="forminput" v-model="member.active">
              <option
                  v-for="(value, index) in activestates"
                  :value="index"
                  :key="value"
                  :selected="index===member.active">
                {{ value }}
              </option>
            </select>
          </div>
        </div>

        <input v-if="mid==0" class="button" type="submit" value="Submit" />
        <input v-if="mid>0" class="button" type="submit" value="Submit changes" />
        <button v-if="mid>0" class="button" @click.prevent="deleteMember">Delete member</button>
        <router-link class="button" :to="{name: 'admin members'}">Cancel</router-link>
      </form>

    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import useSettings from '@/composables/useSettings';
import useMembers from '@/composables/useMembers';
import { useRouter } from 'vue-router';

export default {
  name: "AdminMemberDataContent",
  components: {
  },
  props: {
    mid: String
  },

  setup(props) {
    console.log('AdminMemberDataContent setup entered');

// EXTERNAL PROPERTIES
    const {roles, positions, activestates } = useSettings();
    const { getMember, changeMember } = useMembers();
    const router = useRouter();

// DATA
    const loading = ref(null);
    const posvals = ['Select a position'].concat(positions);
    const member = ref(null);
    const positionvalidationerror = ref('');

// FUNCTIONS
    const handleSubmit = async (submitmember) => {
      console.log('handleSubmit entered');

      positionvalidationerror.value = '';
      if (member.value.position == 'Select a position') {
        positionvalidationerror.value = 'You must select a position';
        return false
      } else {
        await changeMember(props.mid, member.value);
        router.push({name: 'admin members'});
      }

      console.log('handleSubmit exited');
    }

    const deleteMember = async () => {
      console.log('deleteMember entered');
      if(confirm('are you sure?')) {
        await changeMember(props.mid, null);
        router.push({name: 'admin members'});
      }
      console.log('deleteMember exited');
    }

    const loadData = async () => {
      console.log('loadData entered');
      loading.value = true;

      if (props.mid == 0) {
        // new member
        member.value = {
          firstname: '',
          lastname: '',
          email: '',
          position: 'Select a position',
          role: 'member',
          active: 1
        }
      } else {
        // load the member details
        member.value = await getMember(props.mid);
        console.log('MEMBER DATA:', member.value)
      }

      loading.value = false;
      console.log('loadData exited');
    }

// ENTRY POINT
    loadData();

    console.log('AdminMemberDataContent setup exited');
    return {
      loading,
      member,
      roles,
      posvals,
      positionvalidationerror,
      activestates,
      handleSubmit,
      deleteMember,
    };

  }

}
// EXTERNAL PROPERTIES
// COMPUTED PROPERTIES
// DATA
// FUNCTIONS
// ENTRY POINT
// EXPOSED PROPERTIES

</script>

<style>

.forminput {
  width: 100%;
}

</style>

<style scoped>

.formcontainer {
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.formitem {
  display: flex;
}

.left {
  margin: 5px 5px 0 0 ;
  padding: 5px;
  background: #f0f0f0;
  flex: 0 0 28%;
}

.right {
  margin: 5px 0 0 5px;
  padding: 5px;
  flex: 0 0 70%;
  max-width: 70%;
  background: #f0f0f0;
}

.links {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
}

.linkitem {
  overflow-wrap: break-word;
  font-weight: bold;
}

</style>

