<template>
  <Error v-if="unrecoverable" errtype="unrecov" :message="autherror" />
  <div v-else-if="loggedinuser">
    <slot name="viewcontent"></slot>
  </div>
  <LoginForm v-else />
</template>

<script>
import useAuth from '@/composables/useAuth.js';

export default {
  name: 'Authorise',
  components: {
  },
  setup() {
    console.log('Authorise setup entered');

// EXTERNAL PROPERTIES
    const { loggedinuser, autherror, unrecoverable } = useAuth();
    console.log('ERROR TYPE:')
    console.log('Authorise setup exited');
// EXPOSED PROPERTIES
    return {
      loggedinuser,
      autherror,
      unrecoverable
    };
  }
}
</script>


<style scoped>

</style>