import {ref} from 'vue';
import useAuth from "@/composables/useAuth";
import useEvents from "@/composables/useEvents";

// EXTERNAL PROPERTIES
const { getData, postData } = useAuth();
//const { eventlistformember} = useEvents();

// FUNCTIONS
const getPredictedAttendance = async (eid) => {
    console.log("getPredictedAttendance entered");

    const url = 'attendance/predicted/' + eid;
    const data = await getData(url);

    console.log("getPredictedAttendance exited");
    return data;
}
//!!!
const getMemberPredictedAttendance = async (eid, mid) => {
    console.log("getMemberPredictedAttendance entered");

    const url = 'attendance/predicted/' + eid + '/' + mid;
    const data = await getData(url);

    console.log("getMemberPredictedAttendance exited");
    return data;
}

const changePredictedAttendanceState = async (eid, mid, state) => {
    console.log("changePredictedAttendanceState entered");

    // update on the server
    const url = 'attendance/predicted/changestate/' + eid + '/' + mid + '/' + state;
    const newvalue = await postData(url, null);

    console.log("changePredictedAttendanceState exited");
    return newvalue;
}

const changePredictedAttendancePosition = async (eid, mid, newposition) => {
    console.log("changePredictedAttendancePosition entered");

    const url = 'attendance/predicted/changeposition/' + eid + '/' + mid + '/' + newposition;
    const newvalue = await postData(url, null);
    console.log('NEW POSITION VALUE:', newvalue);
    console.log("changePredictedAttendancePosition exited");
    return newvalue;
}

const changePredictedDeps = async (eid, position, depslist) => {
    console.log("changePredictedDeps entered");

    const url = 'attendance/predicted/changedeps/' + eid;
    const newvalue = await postData(url, {position: position, name: depslist});

    console.log("changePredictedDeps exited");
    return newvalue;
}

const getActualAttendance = async (eid, mid) => {
    console.log("getActualAttendance entered");

    const url = 'attendance/actual/' + eid + '/' + mid;
    const data = await getData(url);

    console.log("getActualAttendance exited");
    return data;
}

const changeActualAttendance = async (eid, mid, state) => {
    console.log("changeActualAttendance entered");
    // update on the server
    const url = 'attendance/actual/changestate/' + eid + '/' + mid + '/' + state;
    const newvalue = await postData(url, null);

    console.log("changeActualAttendance exited");
    return newvalue;
}

const changeActualOthers = async (eid, otherslist) => {
    console.log("changeActualOthers entered");

    const url = 'attendance/actual/changeothers/' + eid;
    const newvalue = await postData(url, {names: otherslist});

    console.log("changeActualOthers exited");
    return newvalue;
}

// EXPOSED PROPERTIES
const useAttendance = () => {
    console.log("useAttendance entered");

    console.log("useAttendance exited");
    return {
        getPredictedAttendance,
        getMemberPredictedAttendance,
        changePredictedAttendanceState,
        changePredictedAttendancePosition,
        changePredictedDeps,
        getActualAttendance,
        changeActualAttendance,
        changeActualOthers
    };
}

export default useAttendance;
