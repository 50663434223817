<template>

   <textarea v-model="depslist" placeholder="add deps" @change="doChange"></textarea>

</template>

<script>

import useAttendance from '@/composables/useAttendance';
import { ref } from "vue";

export default {
  name: "EventPredictedDeps",
  props: {
    eid: String,
    position: String,
    deps: Array //of one item containing an object
  },
  setup (props, { emit }) {
    console.log('EventPredictedDeps setup entered');

// EXTERNAL PROPERTIES
    const { changePredictedDeps } = useAttendance();

    // DATA
    const depslist = ref(null);


// FUNCTIONS
    const doChange = async () => {
      console.log('doChange entered', depslist);
      console.log('Depslist value',depslist.value);

      // newvalue is the whole content of predictedattendance - not actually used here at present
      const newvalue = await changePredictedDeps(props.eid, props.position, depslist.value);
      console.log('NEW DEPS VALUE:', newvalue);
      console.log('doChange exited');
    }

    console.log('deps prop:', props.deps[0]);
    depslist.value = (props.deps[0]?props.deps[0].name:""); // string of names
    console.log('Depslist value"',depslist.value);
    console.log('EventPredictedDeps setup exited');
// EXPOSED PROPERTIES
    return {
      depslist,
      doChange
    };
  }
}

</script>

<style scoped>

textarea {
  width: 100%;
}

</style>