<template>

    <div class="container membercontainer">
      <div class="member">
        <div class="first column clickable"  @click="goToDetails">
          {{ member.firstname }} {{ member.lastname }}
        </div>
        <div class="second column">
          {{ member.role }}
        </div>
        <div class="third column">
          {{ activestates[member.active] }}
        </div>
        <div class="last column">
          <button class="button littlebutton" @click="resetPassword">Reset password</button>
        </div>
      </div>
    </div>

</template>

<script>
import useSettings from "@/composables/useSettings";
import useMembers from "@/composables/useMembers";
import {useRouter} from 'vue-router';

export default {
  name: "AdminSingleMember",
  props: {
    member: Object
  },
  components: {
  },

  setup(props) {
    console.log('AdminSingleMember setup entered');

    const { roles, activestates } = useSettings();
    const { changePassword } = useMembers();
    const router = useRouter();

// FUNCTIONS
    const resetPassword = async () => {
      console.log('resetPassword entered');

      const newpassword = prompt('Enter password or accept the suggestion', generateP(8));
      console.log('NEW PASSWORD:', newpassword)

      if (newpassword) {
        const alertmessage = await changePassword(props.member.id, newpassword, true);
        console.log('ALERT MESSAGE:', alertmessage);

        alert(alertmessage);
      }
      console.log('resetPassword exited');
    }

    const generateP = (num) => {
      console.log('generateP entered');
      let pass = '';
      const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
          'abcdefghijklmnopqrstuvwxyz0123456789@#$';

      for (let i = 1; i <= num; i++) {
        const char = Math.floor(Math.random()
            * str.length + 1);
        pass += str.charAt(char)
      }
      console.log('generateP exited');
      return pass;
    }

const goToDetails = () => {
      router.push({name: 'member details', params: {mid: props.member.id }});
    }

    console.log('AdminSingleMember setup exited');

// EXPOSED PROPERTIES
    return {
      roles,
      activestates,
      goToDetails,
      resetPassword
    };
  }
}

</script>

<style scoped>

.membercontainer {
  flex-direction: column;
  padding: 5px;
}

.column {
  background: #f0f0f0;
  padding: 5px;
  margin: 4px 2px;
}

.first {
  width: 30%;
}

.second {
  width: 20%;
}

.third {
  width: 20%;
}

.last {
  width: 30%;
}
.member {
  display: flex;
}

.littlebutton {
  margin: 2px;
  padding: 2px 0;
  font-size: 0.8rem;
  width: 95%;

}


</style>


