<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <RegisterAvailabilityContent  :eid="eid"/>
    </template>
  </Authorise>

</template>

<script>
import RegisterAvailabilityContent from '@/components/viewcontents/RegisterAvailabilityContent';

export default {
  name: 'RegisterAvailability',
  components: {
    RegisterAvailabilityContent
  },
  props: {
    eid: String
  }
};

</script>

<style scoped>

</style>
