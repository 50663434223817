<template>

  <div>
    <textarea v-model="otherslist" placeholder="add others" @change="doChange"></textarea>
  </div>

</template>

<script>

import useAttendance from '@/composables/useAttendance';
import { ref } from "vue";

export default {
  name: "AdminEventActualOthers",
  props: {
    eid: String,
    others: String
  },
  setup (props, { emit }) {
    console.log('EventActualOthers setup entered');



// EXTERNAL PROPERTIES
    const { changeActualOthers } = useAttendance();

    // DATA
    const otherslist = ref(null);


// FUNCTIONS
    const doChange = async () => {

      // newvalue is the whole content of actualattendance - not actually used here at present
      const newvalue = await changeActualOthers(props.eid, otherslist.value);
      console.log('ACT OTHERS NEW VALUE:', newvalue);

      console.log('doChange exited');
    }

    otherslist.value = (props.others); // string of names

    console.log('EventActualOthers setup exited');
// EXPOSED PROPERTIES
    return {
      otherslist,
      doChange
    };
  }
}

</script>

<style scoped>

textarea {
  width: 100%;
}

</style>