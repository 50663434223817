<template>

  <div>
    <p v-if="urgentfordays<0">Not urgent.</p>
    <p v-else-if="urgentfordays==0" class="urgent">Urgent until midnight tonight</p>
    <p v-else  class="urgent">Urgent for {{ urgentfordays }} days</p>
    <div>
      To change, select the number of urgent days.

      <select class="inline" v-model="requireddays" @change="doChange">
        <option
            v-for="option in numdaysarray"
            :value="option"
            :key="option"
            :selected="option === requireddays"
        >{{ option }}</option>
      </select>
    </div>
  </div>

</template>

<script>
import useEvents from "@/composables/useEvents";
import { ref, computed } from 'vue';

export default {
  name: "AdminUrgentEvent",
  props: {
    eid: String,
    urgentuntil: String
  },
  emits: [
    'urgentchanged'
  ],

  setup (props, { emit }) {
    console.log('AdminUrgentEvent setup entered');

// EXTERNAL PROPERTIES
    const { changeUrgent } = useEvents();

// COMPUTED PROPERTIES
    const urgentfordays = computed(() => {
      console.log('urgentfordays entered');

      if (props.urgentuntil === null) {
        console.log('urgentfordays exited');
        return -1;
      } else {
        const nowdays = Math.floor(new Date().getTime()/86400000);
        const urgentuntildays =  Math.floor(new Date(props.urgentuntil).getTime()/86400000);

        console.log('urgentfordays exited');
        return urgentuntildays - nowdays;
      }
    });

// DATA
    const numdaysarray = [0, 1, 2, 3, 4, 5];
    const requireddays = ref(0);
    requireddays.value = urgentfordays.value;

// FUNCTIONS
    const doChange = async () => {
      console.log('doChange entered');

      const newvalue = await changeUrgent(props.eid, requireddays.value)
      emit('urgentchanged', newvalue);

      console.log('doChange exited');
    }

    console.log('urgentfordays exited');
// EXPOSED PROPERTIES
    return {
      numdaysarray,
      urgentfordays,
      requireddays,
      doChange
    };
  }
}

</script>

<style scoped>
.inline {
  display: inline-block;
  padding: 0 5px;
}

.urgent {
  color: darkorange;
}

</style>