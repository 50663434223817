<template>

  <Navbar />
  <AuthoriseAdmin>
    <template v-slot:viewcontent>
      <AdminEventsContent />
    </template>
  </AuthoriseAdmin>

</template>

<script>
import AdminEventsContent from '@/components/viewcontents/AdminEventsContent';

export default {
  name: 'AdminEvents',
  components: {
    AdminEventsContent
  }
};

</script>

<style scoped>

</style>