<template>
  <Waiting v-if="loading" message="loading details..." />

  <div v-else>
    <div class="pageheader">
      <p> {{ formatteddate }}  {{ formattedstart }}  {{ event.title }}</p>
      <p>Availability summary </p>
    </div>

    <div class="links">
      <router-link class="link" :to="{name: 'event details', params: {eid: eid } }">Event details</router-link>
      <router-link class="link" :to="{name: 'admin events'}">Back to list</router-link>
      <router-link class="link" :to="{name: 'actual attendance', params: {eid: eid } }">Actual attendance</router-link>
    </div>

    <div class="flexcontainer positioncontainer">

      <div class="position" v-for="pos in playersanddeps" :key="pos.position">
        <AdminPositionPredictedAttendance :eid="eid" :position="pos.position" :players="pos.players" :deps=pos.deps @poschanged="updateView" />
      </div>

    </div>

  </div>

</template>

<script>
import AdminPositionPredictedAttendance from '@/components/events/AdminPositionPredictedAttendance';
import useSettings from "@/composables/useSettings";
import useEvents from "@/composables/useEvents";
import useMembers from '@/composables/useMembers';
import { ref} from 'vue';

export default {
  name: "AdminEventPredictedAttendanceContent",
  props: {
    eid: String,
  },
  components: {
    AdminPositionPredictedAttendance
  },
  emits: [
    'rerender'
  ],
  setup(props, { emit }) {
    console.log('AdminEventPredictedAttendanceContent setup entered');

// EXTERNAL PROPERTIES
    const { positions } = useSettings();
    const { getMemberList } = useMembers();
    const { getEventData } = useEvents();

// DATA
    const loading = ref(null)
    const memberlist = ref(null);
    const event = ref(null);
    const formatteddate = ref(null);
    const formattedstart = ref(null);
    const playersanddeps = ref(null);

// FUNCTIONS
    const updateView = () => {
      console.log('updateView entered');
      emit('rerender')
    }

    const getPlayersInPosition = (list, position) => {
      console.log('getPlayersInPosition entered');
      const players =
          list.filter((entry) => {
            return entry.position == position;
          });
      console.log('getPlayersInPosition exited');
      return players;
    }

    const createPlayersAndDepsList = (memberavail, depavail) => {
      console.log('createPlayersAndDepsList entered');

      // combines memavail and depavail to create a list by position
      let psandds = [];
      positions.forEach((pos) => {
        const players = getPlayersInPosition(memberavail, pos);
        const deps = getPlayersInPosition(depavail, pos);

        psandds.push({
          position: pos,
          players: players,
          deps: deps
        });
      });

      console.log('createPlayersAndDepsList exited');
      return psandds;
    }

    const createAllMembersAvailabilityList = () => {
      console.log('createAllMembersAvailabilityList entered');

      // extract the member availability
      let memberavail = [];
      if (event.value.predictedattendance) {
        memberavail = event.value.predictedattendance.memberavail;
      }

      // create the availability list for all members
      let availlist = [];
      memberlist.value.forEach((member) => {
        const recordedmemberavail = memberavail.find((entry) => {
          return entry.mid == member.id;
        });
        if (recordedmemberavail) {
          availlist.push({
            position: (recordedmemberavail.position ? recordedmemberavail.position : member.position),
            available: recordedmemberavail.available,
            id: member.id,
            name: member.firstname + ' ' + member.lastname
          });
        } else {
          availlist.push({
            position: member.position,
            available: "0",
            id: member.id,
            name: member.firstname + ' ' + member.lastname
          });
        }
      });
      console.log('createAllMembersAvailabilityList exited');
      // returns an array of objects (id, name, position, available}, one per member in the memberslist
      return availlist;
    }

    const loadData = async () => {
      console.log('loadDAta entered');
      loading.value = true;

      // initialises memberlist
      memberlist.value = await getMemberList();

      // load the event details including predicted attendance
      event.value = await getEventData(props.eid);
      formatteddate.value = new Date(event.value.date).toDateString();
      formattedstart.value = event.value.starttime.substring(0,5);

      // create a list of availability for all memebrs from retrieved predicted attendance
      // returns an array of objects (id, name, position, available}, one per member in the memberslist
      const memavail = createAllMembersAvailabilityList();
      // create a list of availability for all memebrs from retrieved predicted attendance
      // an array of objects (name, position)
      const depavail = (event.value.predictedattendance?event.value.predictedattendance.depavail:[]);
      // create the plaers and deps list
      playersanddeps.value = createPlayersAndDepsList(memavail, depavail);
      console.log('PLAYERSANDDEPS:', playersanddeps.value);

      loading.value = false;
      console.log('loadData exited');
    }

// ENTRYPOINT
    loadData();

    console.log('AdminEventPredictedAttendanceContent setup exited');
// EXPOSED PROPERTIES
    return {
      loading,
      event,
      formatteddate,
      formattedstart,
      playersanddeps,
      updateView
    }
  }
}
</script>

<style scoped>
.positioncontainer {
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.links {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
}

</style>
