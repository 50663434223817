<template>
  <div v-if="event === null"> Loading details... </div>
  <div v-else>
    <div class="pageheader">
      <p> {{ formatteddate }}  {{ formattedstart }}  {{ event.title }}</p>
      <p>Attendance summary </p>
    </div>
    <div class="links">
      <router-link class="link" :to="{name: 'event details', params: {eid: eid } }">Event details</router-link>
      <router-link class="link" :to="{name: 'admin events'}">Back to list</router-link>
      <router-link class="link" :to="{name: 'predicted attendance', params: {eid: eid } }">Predicted attendance</router-link>
    </div>
    <div class="flexcontainer">
      <div class="attendcontainer">
        <div class="left">
          NAME
        </div>

        <div class="right">
          ATTENDING
        </div>
      </div>

      <div  class="attendcontainer" v-for="member in attendancelist" :key="member.id">
        <div class="left">
          {{ member.name }}
        </div>
        <MemberEventActualAttendance class="right" :eid="eid" :mid="member.id" :memactatt="member.attending"/>
      </div>
    </div>

    <div class="flexcontainer">
      OTHERS ATTENDING
      <div>
        <EventActualOthers :eid="eid" :others="otherslist" />

      </div>
    </div>

  </div>
</template>

<script>
import MemberEventActualAttendance from '@/components/events/MemberEventActualAttendance';
import useMembers from '@/composables/useMembers';
import useEvents from '@/composables/useEvents';
import useAttendance from '@/composables/useAttendance';
import {ref} from 'vue';
import EventActualOthers from '@/components/events/AdminEventActualOthers';

export default {
name: "AdminEventActualAttendanceContent",
  components: {
    EventActualOthers,
    MemberEventActualAttendance
  },
  props: {
    eid: String
  },
  setup(props) {
    console.log('AdminEventActualAttendanceContent setup entered');

// EXTERNAL PROPERTIES
    const { getMemberList } = useMembers();
    const { getEventData } = useEvents();

// DATA
    const memberlist = ref(null);
    const event = ref(null);
    const attendancelist = ref(null)
    const otherslist = ref(null)
    const formatteddate = ref(null);
    const formattedstart = ref(null);

// FUNCTIONS
    const createAllMembersAttendanceList = () => {
      console.log('createAllMembersAttendanceList entered');

      // extract the member attendance
      let memberactual = [];
      if (event.value.actualattendance) {
        memberactual = event.value.actualattendance.memberactual;
      }

      let actuallist = [];

      memberlist.value.forEach((member) => {
        actuallist.push({
          id: member.id,
          name: member.firstname + ' ' + member.lastname,
          attending:
              (memberactual.findIndex((entry) => {
            return entry.mid == member.id;
          }) === -1 ? 1 : 2)
        })
      });

      console.log('createAllMembersAttendanceList exited');
      // returns an array of objects (id, name, attending}, one per member in the memberslist
      return actuallist;
    }

    const loadData = async () => {
      console.log('loadData entered');
      // initialises memberlist useMembers
      memberlist.value = await getMemberList();

      // load the event details including actual attendance
      event.value = await getEventData(props.eid);
      formatteddate.value = new Date(event.value.date).toDateString();
      formattedstart.value = event.value.starttime.substring(0,5);

      attendancelist.value = createAllMembersAttendanceList();

      // extract the others attendance
      if (event.value.actualattendance) {
        otherslist.value = event.value.actualattendance.otheractual;
      }

      console.log('loadData exited');
    }

// ENTRY POINT
    loadData();

    console.log('AdminEventActualAttendanceContent setup exited');
    return {
      event,
      formatteddate,
      formattedstart,
      attendancelist,
      otherslist,
      loadData
    };
  }

}
// EXTERNAL PROPERTIES
// COMPUTED PROPERTIES
// DATA
// FUNCTIONS
// ENTRY POINT
// EXPOSED PROPERTIES

</script>

<style scoped>

.flexcontainer {
  flex-direction: column;
}

.attendcontainer {
  justify-content: space-between;
  display: flex;
}

.left {
  display: inline-block;
  flex: 0 0 40%
}

.right {
  display: inline-block;
  flex: 0 0 40%
}

.links {
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
}
</style>