<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <AdminEventDetailsContent :eid="eid"/>
    </template>
  </Authorise>

</template>

<script>
import AdminEventDetailsContent from '@/components/viewcontents/AdminEventDetailsContent';

export default {
  name: 'AdminEventDetails',
  components: {
    AdminEventDetailsContent
  },
  props: {
    eid: String
  }
};

</script>

<style scoped>

</style>
