<template>

  <div>
    <select :id="selectid" :value="memberpredictedposition" @change="doChange">
      <option
          v-for="option in positions"
          :value="option"
          :key="option"
          :selected="option === memberpredictedposition"
      >{{ option }}</option>
    </select>
  </div>

</template>

<script>

import useAttendance from '@/composables/useAttendance';
import useSettings from "@/composables/useSettings";
import {ref} from "vue";

export default {
  name: "EventMemberPredictedAttendance",
  props: {
    eid: String,
    mid: String,
    mempredatt: Object
  },
  setup (props, { emit }) {
    console.log('MemberEventPredictedPosition setup entered');

// EXTERNAL PROPERTIES
    const { positions } = useSettings();
    const { changePredictedAttendancePosition } = useAttendance();

// DATA
    const memberpredictedposition = ref(props.mempredatt.position);
    const selectid = 'selid'+props.eid+props.mid;

// FUNCTIONS
    const doChange = async (event) => {
      console.log('doChange entered', event.target.value);
      memberpredictedposition.value = await changePredictedAttendancePosition(props.eid, props.mid, event.target.value);

      emit('poschanged');
      console.log('doChange exited');
    }

    console.log('MemberEventPredictedPosition setup exited')
// EXPOSED PROPERTIES
    return {
      memberpredictedposition,
      positions,
      selectid,
      doChange
    };
  }
}
/* the mempredatt object !!!maybe null!!! or
    {mid, name, position, available (1-No, 2-Yes)}
*/

</script>

<style scoped>
.inline {
  display: inline-block;
  padding: 0 5px;
}
</style>