<template>

  <Error v-if="unrecoverable" errtype="unrecov" :message="autherror" />
  <Error v-else-if="loggedinuser && loggedinuser.role == 'member'" errtype="auth" message="You are not authorised to access this page" />
  <div v-else-if="loggedinuser">
    <slot name="viewcontent"></slot>
  </div>
  <LoginForm v-else />

</template>

<script>
import useAuth from '@/composables/useAuth.js';

export default {
  name: 'AuthoriseAdmin',
  components: {
  },
  setup() {
    console.log('AuthoriseAdmin setup entered');

// EXTERNAL PROPERTIES
    const { loggedinuser, autherror, unrecoverable } = useAuth();

    console.log('AuthoriseAdmin setup exited');
// EXPOSED PROPERTIES
    return {
      loggedinuser,
      autherror,
      unrecoverable
    };
  }
}
</script>

<style scoped>

</style>