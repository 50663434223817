<template>

  <div v-if="memberactualattendance !== null">
    <div class="inline">
      <input
          type="radio"
          v-model="memberactualattendance"
          :value="2"
          :name="'evmemactatt_' + eid +'_'+mid"
          @change="doChange"
      />
      <label>I'm here</label>
    </div>

    <div class="inline">
      <input
          type="radio"
          v-model="memberactualattendance"
          :value="1"
          :name="'evmemactatt_' + eid +'_'+mid"
          @change="doChange"
      />
      <label>Not here</label>
    </div>
  </div>

</template>

<script>

import useAttendance from '@/composables/useAttendance';
import {ref} from "vue";

export default {
  name: "MemberEventActualAttendance",
  props: {
    eid: String,
    mid: String,
    memactatt: Number
  },
  emits: [
    'actattchanged'
  ],
  setup (props, { emit }) {
    console.log('MemberEventActualAttendance setup entered', props.eid, props.mid, props.memactatt);

// EXTERNAL PROPERTIES
    const { changeActualAttendance } = useAttendance();

// DATA
    const memberactualattendance = ref(1);

// FUNCTIONS
    const doChange = async () => {
      console.log('doChange entered');

      // newvalue is the whole content of actualattendance - not actually used here at present
      const newvalue = await changeActualAttendance(props.eid, props.mid, memberactualattendance.value);
      emit('actattchanged', newvalue);

      console.log('doChange exited');
    }

// ENTRYPOINT
    if (props.memactatt) {
      memberactualattendance.value = props.memactatt
    }

    console.log('MemberEventActualAttendance setup exited')
// EXPOSED PROPERTIES
    return {
      memberactualattendance,
      doChange
    };
  }
}
/* the memactatt object !!!maybe null!!! or
    {mid, name, attending (1-No, 2-Yes)}
*/

</script>

<style scoped>
.inline {
  display: inline-block;
  padding: 0 5px;
}
</style>