import { createRouter, createWebHistory } from 'vue-router'
import MemberEvents from '@/views/MemberEvents.vue'
import Meetings from '@/views/Meetings.vue'
import AdminEvents from '@/views/AdminEvents.vue'
import AdminMembers from '@/views/AdminMembers.vue'
import AdminEventDetails from '@/views/AdminEventDetails.vue'
import AdminEventPredictedAttendance from '@/views/AdminEventPredictedAttendance.vue'
import AdminEventActualAttendance from '@/views/AdminEventActualAttendance.vue'
import RegisterAvailability from '@/views/RegisterAvailability.vue'
import RegisterAttendance from '@/views/RegisterAttendance.vue'
import AdminMemberDetails from '@/views/AdminMemberData.vue'

const routes = [

  {
    path: '/memberevents',
    name: 'member events',
    component: MemberEvents,
    alias: '/'
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: Meetings,
  },
  {
    path: '/adminevents',
    name: 'admin events',
    component: AdminEvents,
  },
  {
  path: '/adminmembers',
    name: 'admin members',
    component: AdminMembers,
  },
  {
    path: '/member/detail/:mid',
    name: 'member details',
    component: AdminMemberDetails,
    props: true
  },
  {
    path: '/event/detail/:eid',
    name: 'event details',
    component: AdminEventDetails,
    props: true
  },
  {
    path: '/event/predicted/:eid',
    name: 'predicted attendance',
    component: AdminEventPredictedAttendance,
    props: true
  },
  {
    path: '/event/actual/:eid',
    name: 'actual attendance',
    component: AdminEventActualAttendance,
    props: true
  },
  {
    path: '/register/availability/:eid',
    name: 'register availability',
    component: RegisterAvailability,
    props: true
  },
  {
    path: '/register/attendance/:eid',
    name: 'register attendance',
    component: RegisterAttendance,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
