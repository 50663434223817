<template>

    <div class="container">
      <div v-if="errtype == 'auth' ">
        <div class="error errorheader">!!! Authorisation Error !!!</div>
        <p>{{ message }}</p>
      </div>
      <div v-else>
        <div class="error errorheader">!!! Unrecoverable Error !!!</div>
        <p>Sorry! There is a problem.</p>
        <p>Try restarting the app by clicking 'Hawk Green Band IT' (top left)</p>
        <p>If that fails, report the problem to itsupport@hgband.uk</p>
        <p>Please describe what you were doing and include the following: </p>
        <p>{{ swversion }} Error message: {{ message }}</p>
      </div>
    </div>

</template>

<script>
import useSettings from '@/composables/useSettings';

export default {
  name: "Error",
  components: {},
  props: {
    errtype: String,
    message: String
  },
  setup() {
    console.log("Error setup entered");

    const { swversion } = useSettings();

    console.log("Error setup exited");
    return { swversion }
  }
}
</script>

<style scoped>

.errorheader {
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
}

.errormessage {
  font-size: 1.2rem;
  font-style: italic;
}

</style>