<template>
  <Waiting v-if="loading" message="loading events..." />

  <div v-else>

    <div class="pageheader">Register your availability</div>
    <div class="flexcontainer thiscontainer">
      <div>
        <p><span>Date: </span> {{ new Date(event.date).toDateString() }} </p>
        <p><span>Start time: </span> {{ event.starttime.substring(0,5) }} </p>
        <p><span>Title: </span> {{ event.title }}</p>
        <p><span>Venue: </span> {{ event.location }}</p>
        <p><span>Event type: </span> {{ event.type }}</p>
        <p><span>Event status: </span> {{ event.status }}</p>
        <p><span>Info: </span> {{ event.info }}</p>

        <p>Can you attend? </p>
      </div>
      <div>
        <MemberEventPredictedAttendance :mempredatt="mempredatt" :eid="eid" :mid="mid" @predattchanged="changed"/>
      </div>
    </div>
  </div>
</template>

<script>

import MemberEventPredictedAttendance from '@/components/events/MemberEventPredictedAttendance';
import Waiting from '@/components/global/Waiting';
import useAuth from '@/composables/useAuth';
import useAttendance from '@/composables/useAttendance';
import useEvents from '@/composables/useEvents';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {

name: "RegisterAvailabilityContent",
  components: {
    MemberEventPredictedAttendance,
    Waiting
  },
  props: {
    eid: String
  },
  setup(props) {
    console.log('RegisterAvailabilityContent setup entered');

// EXTERNAL PROPERTIES
    const { loggedinuser } = useAuth();
    const { getMemberPredictedAttendance } = useAttendance();
    const { getEventDetails } = useEvents();
    const router = useRouter();

// DATA
    const mid = loggedinuser.value.id;
    const mempredatt = ref(null);
    const event = ref(null);
    const loading = ref(null);

// FUNCTIONS
    const changed = () => {
      router.push({name: 'member events'});
    }

    const loadData = async () => {
      loading.value = true;

      event.value = await getEventDetails(props.eid);
      console.log('Event:', event.value);

      mempredatt.value = await getMemberPredictedAttendance(props.eid, mid);
      console.log('Mempredatt:', mempredatt.value);

      loading.value = false;
    }

// ENTRYPOINT
    loadData();

    console.log('RegisterAvailabilityContent setup exited');
// EXPOSED PROPERTIES
    return {
      mempredatt,
      event,
      mid,
      loading,
      changed
    }
  }

}
</script>

<style scoped>
  .thiscontainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .thiscontainer div {
  }
</style>