<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <AdminEventPredictedAttendanceContent  :eid="eid" :key="componentkey" @rerender="reRender"/>
    </template>
  </Authorise>

</template>

<script>
import AdminEventPredictedAttendanceContent from '@/components/viewcontents/AdminEventPredictedAttendanceContent';
import { ref  } from 'vue';

export default {
  name: 'AdminEventPredictedAttendance',
  components: {
    AdminEventPredictedAttendanceContent
  },
  props: {
    eid: String
  },
  setup() {
    const componentkey = ref(0);
    const reRender = () => {
      // this is required because eid does not change when the event properties do
      // so changes do not flow down the stack if position is changed
      // since change of position needs a rerender, the component has to be recreated
      console.log('reRender entered');
      componentkey.value += 1;
    }
    return { componentkey, reRender }
  }
};

</script>

<style scoped>

</style>
