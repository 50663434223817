// EXTERNAL PROPERTIES

// DATA
// Client data
const swversion = "Release version 2021-2 rev 10"
//const homeurl = "http://localhost:8080";
const homeurl = "https://our.hawkgreenband.co.uk";

// Server data
//const baseurl = "http://localhost:8000/";
const baseurl = "https://server.our.hawkgreenband.co.uk/";
const expiry = 10000000; //  about 4 months in seconds
const sessionexpiry = 86400; // 1 day in seconds

// Constants
const positions = [
    'Conductor',
    'Sop',
    'Prin cornet',
    'FR cornet',
    'Rep',
    '2nd cornet',
    '3rd cornet',
    'Flugel',
    'Solo horn',
    '1st horn',
    '2nd horn',
    '1st bari',
    '2nd bari',
    '1st trom',
    '2nd trom',
    'Bass trom',
    'Solo euph',
    '2nd euph',
    'Eb bass',
    'Bb bass',
    'Perc kit',
    'Perc tuned',
    'Extra',
    'Non player'];

const eventtypes = [
    'concert (full band)',
    'concert (small band)',
    'contest',
    'rehearsal',
    'bierkeller',
    'meeting',
    'other'
];

const eventstatuses = [
    'confirmed',
    'provisional',
    'enquiry',
    'cancelled'
];

const roles = {
    super: 'Superuser',
    admin: 'Administrator',
    comm: 'Committee',
    member: 'Member'
};

const activestates = [
    'Not active', // index 0
    'Active', // index 1
]



const useSettings = () => {
    console.log("useSettings entered");

    console.log("useSettings exited");
// EXPOSED PROPERTIES
    return {
        swversion,
        baseurl,
        homeurl,
        expiry,
        sessionexpiry,
        positions,
        eventtypes,
        eventstatuses,
        roles,
        activestates
    };
}

export default useSettings;
