<template>

  <Navbar />
  <Authorise>
    <template v-slot:viewcontent>
      <RegisterAttendanceContent  :eid="eid"/>
    </template>
  </Authorise>

</template>

<script>
import RegisterAttendanceContent from '@/components/viewcontents/RegisterAttendanceContent';

export default {
  name: 'RegisterAttendance',
  components: {
    RegisterAttendanceContent
  },
  props: {
    eid: String
  }
};

</script>

<style scoped>

</style>
