import { ref } from 'vue';
import useAuth from "@/composables/useAuth";

// EXTERNAL PROPERTIES
const {loggedinuser, getData, postData} = useAuth();
// DATA

// FUNCTIONS
const getMemberList = async () => {
    console.log("getMemberList entered");

    const relurl = 'members';
    const memberlist = await getData(relurl);

    console.log("getMemberList exited");
    return memberlist
}


const getMember = async (mid) => {
    console.log("getMember entered");

    const relurl = 'member/' + mid;
    const member =  await getData(relurl);

    console.log("getMember exited");
    return member;
}

const changeMember = async (mid, member) => {
    console.log("changeMembert entered");
    // member is an object
    // mid = 0: new member
    // mid > 0 but member = null: delete member
    // otherwise: change member

    const url = 'member/update/' + mid;
    const newvalue = await postData(url, member);

    console.log("changeMember exited");
    return newvalue;
}

const changePassword = async (mid, newpassword, notify) => {
    console.log("changePassword entered");
    let alertmessage = '';
    if ( !(loggedinuser.value.id == mid || loggedinuser.value.role == 'super' || loggedinuser.value.role == 'admin')) {
        alertmessage = 'You do not have permission to do this';
    } else if (newpassword === null || newpassword.length < 8 || newpassword.length > 12) {
        alertmessage = 'Error: Your password must be 8 to 12 characters in length';
    } else {
        const url = 'member/changepassword/' + mid + (notify?'/notify':'');
        const result = await postData(url, {password: newpassword});
        alertmessage = (result==0?'No change made':'Password reset to '+newpassword);
    }

    console.log("changePassword exited", alertmessage);
    return alertmessage;
}

const useMembers = () => {
    console.log("useMembers entered");

    console.log("useMembers exited");
// EXPOSED PROPERTIES
    return {
        getMemberList,
        getMember,
        changeMember,
        changePassword
    };
}

export default useMembers;

